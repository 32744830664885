module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"БИТ","short_name":"БИТ - Фонд развития ИТ Приднестровья","start_url":"/","background_color":"#5A55CC","theme_color":"#5A55CC","display":"standalone","icon":"src/images/head-logo.svg","legacy":false,"cache_busting_mode":"none","include_favicon":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
